"use client";

import { ShipmentContextProvider } from "@/context/shipment";
import { ShipmentOrderContextProvider } from "@/context/shipmentOrder";
import { ThemeContextProvider } from "@/context/themeContext";
import { SessionProvider } from "next-auth/react";

interface ProvidersProps {
  children: React.ReactNode;
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <ThemeContextProvider>
      <SessionProvider>
        <ShipmentContextProvider>
          <ShipmentOrderContextProvider>
            {children}
          </ShipmentOrderContextProvider>
        </ShipmentContextProvider>
      </SessionProvider>
    </ThemeContextProvider>
  );
}