"use client";

import React, { useEffect } from "react";
import liff from "@line/liff";
import { useRouter, useSearchParams } from 'next/navigation';
import { signIn } from "next-auth/react";

const LoginPage = () => {
  const router = useRouter();
  const searchParams = useSearchParams()

  useEffect(() => {
    const initializeLiff = async () => {
      await liff.init({
        liffId: process.env.NEXT_PUBLIC_LIFF_ID as string,
      })

      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        const idToken = await liff.getIDToken();
        const res = await signIn("credentials", {
          line_id_token: idToken,
          line_user_id: profile.userId,
          redirect: false,
        });

        if (res && res.ok) {
          const path = searchParams.get('redirectPath') || '/';
          router.push(path);
        }
      } else {
        const baseUrl = window.location.origin
        const path = searchParams.get('redirectPath')

        liff.login({redirectUri: `${baseUrl}${path}`});
      }
    };

    initializeLiff();
  }, []);

  return (
    <div>Loading...</div>
  );
};

export default LoginPage;
